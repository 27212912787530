export const hero = {
  title: "Omnichannel POS & \n commerce solutions for \n enterprise businesses",
  subtext:
    "SpotOn provides the technology that allows enterprise brands to thrive now — and into the future.",
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/demo",
  },
};

export const productsWithLinks = {
  title: "How can we help \n your business",
  subtext:
    "We’ll help you create a custom solution that combines the hardware and software that makes sense for your business.",
  products: [
    {
      title: "Mobile and online ordering",
      linkTo: "/enterprise/mobile-and-online-ordering",
      imageName: "overview-product-online-ordering.png",
    },
    {
      title: "Cloud point-of-sale",
      linkTo: "/enterprise/pos-terminals",
      imageName: "overview-product-cloud-pos.png",
    },
    {
      title: "Back-of-House management",
      linkTo: "/enterprise/back-of-house-management",
      imageName: "overview-product-centralized-management.png",
    },
  ],
};

export const partners = {
  title: "Powering the world's top enterprise brands",
  mobileImageName: "appetize-partners-mobile.png",
  desktopImageName: "appetize-partners-desktop.png",
};

export const secondaryHeroTitle = {
  title: "World-class technology and service",
  imageName: "overview-secondary-hero-1.png",
  mobileImageName: "overview-secondary-hero-1-mobile.png",
};

export const videoHero = {
  title: "SpotOn Enterprise",
  subtext: "ONE PARTNER",
  backgroundImageName: "overview-video-hero-background.png",
  backgroundImageNameMobile: "overview-video-hero-background-mobile.png",
};

export const productOverview = {
  title: "Contactless solutions for a touch-free world",
  subtext:
    "From mobile ordering and payments to an omnichannel suite of POS devices that all accept contactless payments, give your customers the modern options they've come to expect.",
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/demo",
  },
  subSections: [
    {
      title: "Purpose-built solutions for every concept",
      subtext:
        "Power your staff with easy-to-use software for quick service, table service, and retail, and give your guests self-service options.",
      imageName: "overview-product-1.png",
      cta: {
        title: "Learn more",
        target: "/enterprise/demo",
      },
    },
    {
      title: "Integrate your business systems",
      subtext:
        "The SpotOn enterprise platform, built on an open API architecture, centralizes all ordering and seamlessly integrates with your business operations.",
      imageName: "overview-product-2.png",
      cta: {
        title: "Learn more",
        target: "/enterprise/demo",
      },
    },
  ],
};

export const secondaryHeroTwoCols = {
  title: "Get world-class support",
  subtext:
    "Our team works around the clock to ensure your operations are always running smoothly.",
  image: {
    imageName: "overview-secondary-hero-2.png",
    mobileImageName: "overview-secondary-hero-2-mobile.png",
    subtext: "SUPPORT",
  },
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/demo",
  },
};

export const appetizeProducts = {
  title: "Find a solution that's made for your industry",
  products: [
    {
      title: "Sports & entertainment",
      linkTo: "/enterprise/sports-and-entertainment",
      imageName: "overview-products-sports.png",
    },
    {
      title: "Amusement parks & attractions",
      linkTo: "/enterprise/amusements-and-attractions",
      imageName: "overview-products-attractions.png",
    },
    {
      title: "Professional facilities",
      linkTo: "/enterprise/professional-facilities",
      imageName: "overview-products-facilities.png",
    },
    {
      title: "Education",
      linkTo: "/enterprise/education-campuses",
      imageName: "overview-products-education.png",
    },
    {
      title: "Enterprise food service companies",
      linkTo: "/enterprise/enterprise-food-services",
      imageName: "overview-products-enterprise-food.png",
    },
    {
      title: "Restaurant",
      linkTo: "/restaurants",
      imageName: "overview-products-restaurant.png",
    },
    {
      title: "Retail",
      linkTo: "/enterprise/enterprise-retail",
      imageName: "overview-products-retail.png",
    },
  ],
};

export const footer = {
  title: "Book your consultation",
  subtext:
    "See for yourself how our enterprise cloud POS and management platform can transform the guest experience, provide valuable business insights, and deliver ROI to your enterprise.",
  label: "ONE PARTNER.",
  ctaPrimary: {
    title: "Learn more",
    target: "/enterprise/demo",
  },
};

import React from "react";
import loadable from "@loadable/component";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import {
  hero,
  productsWithLinks,
  secondaryHeroTitle,
  videoHero,
  productOverview,
  partners,
  secondaryHeroTwoCols,
  appetizeProducts,
  footer,
} from "../../data/appetize/overview";
import { enterpriseSupport } from "../../data/spoton-contacts";

import heroVideo from "../../videos/appetize-video.mp4";
// import heroVideoPoster from "../../images/appetize/overview-video-hero-background.png";

const SimpleHero = loadable(() =>
  import("../../components-v2/Sections/Hero/SimpleHero")
);
const ProductsWithLinks = loadable(() =>
  import("../../components-v2/Sections/ProductsWithLinks")
);
const PartnerLogosAppetize = loadable(() =>
  import("../../components-v2/Sections/PartnerLogos/partner-logos-appetize")
);
const SecondaryHeroTitle = loadable(() =>
  import("../../components-v2/Sections/Hero/SecondaryHeroTitle")
);
const VideoHeroBasic = loadable(() =>
  import("../../components-v2/Sections/Hero/VideoHeroBasic")
);
const ProductOverview = loadable(() =>
  import("../../components-v2/Sections/ProductOverview/main-page-overview")
);
const SecondaryHeroTitleTwoCols = loadable(() =>
  import("../../components-v2/Sections/Hero/SecondaryHeroTitleTwoCols")
);
const FooterHero = loadable(() =>
  import("../../components-v2/Sections/Hero/FooterHero")
);
const ProductsGrid = loadable(() =>
  import("../../components-v2/Sections/ProductsGrid")
);

const AppetizeOverview = () => {
  return (
    <Layout className="non-fixed-width a-overview" {...enterpriseSupport}>
      <SEO
        loadChat={false}
        title="Enterprise Point of Sale (POS) System | SpotOn"
        description={hero.title}
        cannonical="https://www.spoton.com/enterprise/"
      />

      <SimpleHero className="mt-32 lg:mt-40" {...hero} />

      <VideoHeroBasic
        className="mt-16 lg:mt-24"
        video={heroVideo}
        // videoType="video/webm"
        // poster={heroVideoPoster}
        {...videoHero}
      />

      <ProductsWithLinks className="mt-20 lg:mt-36" {...productsWithLinks} />

      <PartnerLogosAppetize className="mt-28 lg:mt-52" {...partners} large />

      <SecondaryHeroTitle className="mt-20 lg:mt-36" {...secondaryHeroTitle} />

      <ProductOverview className="mt-20 lg:mt-48" {...productOverview} />

      <SecondaryHeroTitleTwoCols
        className="mt-20 lg:mt-36"
        with247Heading
        {...secondaryHeroTwoCols}
      />

      <ProductsGrid className="mt-20 lg:mt-36" {...appetizeProducts} />

      <FooterHero
        className="mt-20 lg:mt-36 mb-12 lg:mb-20"
        {...footer}
        variant={2}
      />
    </Layout>
  );
};

export default AppetizeOverview;
